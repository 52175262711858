import { Question } from "../types"

import bill from "../images/image/bill.png"
import checklist from "../images/image/checklist.png"
import employeeMajor from "../images/image/employee-major.png"
import employee from "../images/image/employee.png"
import gift from "../images/image/gift.png"
import payment from "../images/image/payment.png"
import productImg from "../images/image/product.png"
import store from "../images/image/store.png"

export const questionsData: Question[] = [
  {
    question: `Sản phẩm`,
    answer: `Cần cải thiện sản phẩm`,
    img: productImg,
  },
  {
    question: `Thái độ nhân viên`,
    answer: `Cần cải thiện thái độ nhân viên`,
    img: employee,
  },
  {
    question: `Nghiệp vụ nhân viên`,
    answer: `Cần cải thiện nghiệp vụ nhân viên`,
    img: employeeMajor,
  },
  {
    question: `Không gian cửa hàng`,
    answer: `Cần cải thiện không gian cửa hàng`,
    img: store,
  },
  {
    question: `Thủ tục thanh toán`,
    answer: `Cần cải thiện thủ tục thanh toán`,
    img: payment,
  },
  {
    question: `Chính sách bảo hành, thu đổi`,
    answer: `Cần cải thiện chính sách bảo hành, thu đổi`,
    img: bill,
  },
  {
    question: `Chương trình khuyến mãi`,
    answer: `Cần cải thiện chương trình khuyến mãi`,
    img: gift,
  },
  {
    question: `Ý kiến khác`,
    answer: `Khác`,
    img: checklist,
    showOtherFeedback: true,
  },
]

export const questionsDataOffline: Question[] = [
  {
    title: `Chương trình khuyến mãi`,
    question: `chuong_trinh_khuyen_mai`,
    answer: `Chương trình khuyến mãi`,
    img: employeeMajor,
  },
  {
    title: `Nhân viên bán hàng`,
    question: `nhan_vien_ban_hang`,
    answer: `Nhân viên bán hàng`,
    img: store,
  },
  {
    title: `Thời gian thanh toán`,
    question: `thoi_gian_thanh_toan`,
    answer: `Thời gian thanh toán`,
    img: payment,
  },
  {
    title: `Túi, hộp đựng trang sức`,
    question: `tui_hop_dung_trang_suc`,
    answer: `Túi, hộp đựng trang sức`,
    img: bill,
  },
  {
    title: `Chất lượng sản phẩm`,
    question: `chat_luong_san_pham`,
    answer: `Chất lượng sản phẩm`,
    img: gift,
  },
  {
    title: `Thiết kế trưng bày`,
    question: `thiet_ke_trung_bay`,
    answer: `Thiết kế/ Trưng bày`,
    img: employee,
  },
  {
    title: `Bảo vệ`,
    question: `bao_ve`,
    answer: `Bảo vệ`,
    img: employeeMajor,
  },
  {
    title: `Thợ nữ trang`,
    question: `tho_nu_trang`,
    answer: `Thợ nữ trang`,
    img: store,
  },

  // {
  //   title: `Ý kiến khác`,
  //   question: `y_kien_khac`,
  //   answer: `Khác`,
  //   img: checklist,
  //   showOtherFeedback: true,
  // },
]
export const questionsDataStoreOffline: Question[] = [
  {
    title: `Nhân viên bán hàng`,
    question: `nhan_vien_ban_hang`,
    answer: `Nhân viên bán hàng`,
    img: store,
  },

  {
    title: `Bảo vệ`,
    question: `bao_ve`,
    answer: `Bảo vệ`,
    img: employeeMajor,
  },
  {
    title: `Sản phẩm`,
    question: `san_pham`,
    answer: `Sản phẩm`,
    img: employeeMajor,
  },
  {
    title: `Khác`,
    question: `khác`,
    answer: `Khác`,
    img: employeeMajor,
  },
]
export const questionsDataOnlineAndDeliveryAtHome: Question[] = [
  {
    title: `Nhân viên giao hàng`,
    question: `nhan_vien_giao_hang`,
    answer: `Nhân viên giao hàng`,
    img: employee,
  },
  {
    title: `Thời gian giao hàng`,
    question: `thoi_gian_giao_hang`,
    answer: `Thời gian giao hàng`,
    img: employee,
  },
  {
    title: `Đóng gói hàng`,
    question: `dong_goi_hang`,
    answer: `Đóng gói hàng`,
    img: employee,
  },
  {
    title: `Nhân viên bán hàng`,
    question: `nhan_vien_ban_hang`,
    answer: `Nhân viên bán hàng`,
    img: store,
  },
  {
    title: `Thời gian xử lý đơn hàng`,
    question: `thoi_gian_xu_ly_don_hang`,
    answer: `Thời gian xử lý đơn hàng`,
    img: payment,
  },
  {
    title: `Chất lượng sản phẩm`,
    question: `chat_luong_san_pham`,
    answer: `Chất lượng sản phẩm`,
    img: gift,
  },
  {
    title: `Chương trình khuyến mãi`,
    question: `chuong_trinh_khuyen_mai`,
    answer: `Chương trình khuyến mãi`,
    img: employee,
  },
]

export const questionsDataOnlineAndCollectInShop: Question[] = [
  {
    title: `Chương trình khuyến mãi`,
    question: `chuong_trinh_khuyen_mai`,
    answer: `Chương trình khuyến mãi`,
    img: employee,
  },
  {
    title: `Nhân viên bán hàng`,
    question: `nhan_vien_ban_hang`,
    answer: `Nhân viên bán hàng`,
    img: store,
  },
  {
    title: `Thời gian thanh toán`,
    question: `thoi_gian_thanh_toan`,
    answer: `Thời gian thanh toán`,
    img: payment,
  },
  {
    title: `Túi, hộp đựng trang sức`,
    question: `tui_hop_dung_trang_suc`,
    answer: `Túi, hộp đựng trang sức`,
    img: bill,
  },
  {
    title: `Thiết kế trưng bày`,
    question: `thiet_ke_trung_bay`,
    answer: `Thiết kế trưng bày`,
    img: employee,
  },
  {
    title: `Bảo vệ`,
    question: `bao_ve`,
    answer: `Bảo vệ`,
    img: employeeMajor,
  },
  {
    title: `Thợ nữ trang`,
    question: `tho_nu_trang`,
    answer: `Thợ nữ trang`,
    img: store,
  },
  {
    title: `Thời gian xử lý đơn hàng`,
    question: `thoi_gian_xu_ly_don_hang`,
    answer: `Thời gian xử lý đơn hàng`,
    img: payment,
  },
]

export const questionsDataWarrantyProduct: Question[] = [
  {
    title: `Nhân viên bán hàng`,
    question: `nhan_vien_ban_hang`,
    answer: `Nhân viên bán hàng`,
    img: employee,
  },
  {
    title: `Thợ nữ trang`,
    question: `tho_nu_trang`,
    answer: `Thợ nữ trang`,
    img: employee,
  },
  {
    title: `Thời gian tiếp nhận`,
    question: `thoi_tiep_nhan`,
    answer: `Thời gian tiếp nhận`,
    img: employee,
  },
  {
    title: `Thời gian bảo hành sản phẩm`,
    question: `thoi_gian_bao_hanh_san_pham`,
    answer: `Thời gian bảo hành sản phẩm`,
    img: employee,
  },
  {
    title: `Phí bảo hành`,
    question: `phi_bao_hanh`,
    answer: `Phí bảo hành`,
    img: employee,
  },
  {
    title: `Chất lượng sản phẩm`,
    question: `chat_luong_san_pham`,
    answer: `Chất lượng sản phẩm`,
    img: employee,
  },
  {
    title: `Túi, hộp đựng trang sức`,
    question: `tui_hop_dung_trang_suc`,
    answer: `Túi, hộp đựng trang sức`,
    img: employee,
  },
  {
    title: `Bảo vệ`,
    question: `bao_ve`,
    answer: `Bảo vệ`,
    img: employeeMajor,
  },
]

export const subQuestions: any = [
  {
    title: `Người thân/Bạn bè giới thiệu`,
    question: `nguoi_than_ban_be`,
    answer: `nguoi_than_ban_be`,
  },
  {
    title: `Quảng cáo truyền thống (Tờ rơi, báo chí...)`,
    question: `quang_cao_truyen_thong`,
    answer: `quang_cao_truyen_thong`,
  },
  {
    title: `Cửa hàng/Nhân viên PNJ`,
    question: `cua_hang_nhan_vien_pnj`,
    answer: `cua_hang_nhan_vien_pnj`,
  },
  {
    title: `Mạng xã hội (Zalo/Facebook/Tiktok)`,
    question: `mang_xa_hoi`,
    answer: `mang_xa_hoi`,
  },
  {
    title: `Google/Website PNJ`,
    question: `google_website`,
    answer: `google_website`,
  },
  {
    title: `Quảng cáo trực tuyến (Google Ads/Facebook Ads/Zalo Ads)`,
    question: `quang_cao_truc_tuyen`,
    answer: `quang_cao_truc_tuyen`,
  },
  {
    title: `Khác`,
    question: `khac`,
    answer: `khac`,
  },
]

export const QuestTionVocCao = [
  {
    title: `Tư vấn viên nhiệt tình, chuyên nghiệp`,
    question: `tu_van_vien`,
    answer: `tu_van_vien`,
  },
  {
    title: `Quy trình mua hàng, thanh toán nhanh chóng`,
    question: `quy_trinh_mua_hang_thanh_toan`,
    answer: `quy_trinh_mua_hang_thanh_toan`,
  },
  {
    title: `Trang sức đa dạng, đặc sắc`,
    question: `bo_suu_tap_trang_suc`,
    answer: `bo_suu_tap_trang_suc`,
  },
  {
    title: `Quà tặng, chương trình ưu đãi hấp dẫn`,
    question: `qua_tang_uu_dai_`,
    answer: `qua_tang_uu_dai`,
  },
  {
    title: `Không gian cửa hàng thoải mái`,
    question: `khong_gian_cua_hang`,
    answer: `khong_gian_cua_hang`,
  },
]
