import "../css/lib.css";
import "../css/main.css";
import "../css/normalize.css";
import "../style.css";
 import {graphql} from 'gatsby';
 
import React, {  useState , useEffect } from "react";
import { useLocation } from '@reach/router';
import {RiseLoader} from 'react-spinners'
import {
    questionsDataStoreOffline,
  
  } from "../data";
  import { useToast } from "@chakra-ui/react";

import {
 
  QuestionV2Component,
  RatingV2Component,
} from "../components/index";
import { ThankYouComponent } from "../components/thank-you";
import {Header} from "../components/language/header"
 import { useStyles } from "../style";
import { Question } from "../types";
import axios from 'axios';
function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// markup
const StoreRatings = ( ) => {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const toast = useToast();

  const storeCode = queryParams.get('store_code');
  const [isSubmit , setIsSubmit] = useState<boolean>(false)
  const [rating, setRating] = useState<number>(-1);
  const [loading , setLoading] = useState<boolean>(false)
  const [result , setResult] = useState<any>();
  const [questionsDataObject , setQuestionsData] = useState<Question[]>(questionsDataStoreOffline);
  useEffect(() => {
    const data = sessionStorage.getItem("ratingData");
    if (data) {
      setResult(JSON.parse(data));
      setIsSubmit(!isSubmit);
    }
  }, []);
   const showErrorMsg = async (msg: string): Promise<void> => {
    toast({
      title: msg || ``,
      status: `error`,
      duration: 2000,
      isClosable: false,
    });
  };

  const sumitData = (data: { [key: string]: any }): void => {
    let payload:{ [key: string]: any } = {};
    if (rating <= 0) {
      showErrorMsg(`Xin hãy chọn đánh giá trải nghiệm!`);
      return;
    }
     if(rating) {
      payload.rating = rating;
    }
    if(storeCode) {
      payload.storeCode = storeCode;
    }
    if(data.y_kien_khac) {
      payload.messages = data.y_kien_khac;
    }
    if(data) {
      delete data.y_kien_khac;
      payload.options = data;
    }
    
    setLoading(true)
    axios.post(process.env.GATSBY_END_POINT + '/ecom-frontend/v1/create-store-review', payload)
    .then(function (response) {
       if(response.status == 200) {
        setLoading(false)
        setIsSubmit(!isSubmit)
        setResult(response)
        sessionStorage.setItem("ratingData", JSON.stringify(response));

      }
    })
    .catch(function (error) {
      console.log(error);
    });
    };

 

  const selectRating = async (value: number): Promise<void> => {
    setRating(value);
  };

 

 

  return (
    <main className={classes.body}>
      <Header/>
         {isSubmit ? <ThankYouComponent rating={rating}  type="store" response={result}/> : 
        <div className="container_store_ratings">
        <RatingV2Component rating={rating} selectRating={selectRating} type="store" />
        <QuestionV2Component
          submitQuestion={sumitData}
          questionsData={questionsDataObject}
          rating={rating}
          type="store"
          placeholder="Hãy để lại ý kiến để PNJ có thể phục vụ Quý khách tốt hơn"
        />
        {loading && 
          <div className={classes.overlayLoading}>
          <RiseLoader color="#fff" style={{'display':'flex' , 'justifyContent':'center' , 'alignItems':'center' , 'height':'100vh'}} />
          </div>
        }
        
      </div>
}
    </main>
  );
};

export default StoreRatings;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;